<template>
<div>
    <form @submit.prevent="create" class="mb-5">
        <b-field
        custom-class=""
        :type="error.email ? 'is-danger' : ''"
        :message="error.email"
        >
        <b-input
            placeholder="Email"
            type="email"
            custom-class="custum-input"
            v-model="user.email"
            disabled> </b-input>
        </b-field>
        <b-field
        custom-class=""
        :type="error.firstName ? 'is-danger' : ''"
        :message="error.firstName"
        >
        <b-input
            placeholder="FirstName"
            custom-class="custum-input"
            v-model="user.firstName"> </b-input>
        </b-field>
        <b-field
        custom-class=""
        :type="error.lastName ? 'is-danger' : ''"
        :message="error.lastName"
        >
        <b-input
            placeholder="LastName"
            custom-class="custum-input"
            v-model="user.lastName"> </b-input>
        </b-field>
        <b-field
        custom-class=""
        :type="error.mobileNumber ? 'is-danger' : ''"
        :message="error.mobileNumber"
        >
        <b-input
            placeholder="MobileNumber"
            custom-class="custum-input"
            v-model="user.mobileNumber"> </b-input>
        </b-field>
    </form>
    <div class="columns is-centered">
        <div class="column">
        <b-button
            type="is-primary"
            expanded
            @click="create"
            class="has-text-weight-bold"
        >
            Create
        </b-button>
        </div>
    </div>
</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import { validation } from '@/services/validation';
import { CreateUser } from '@/api/user';
import { ADMIN } from '@/config/user';

export default {
  components: {},
  data() {
    return {
      user: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
        profil: ADMIN,
      },
      error: {
        email: '',
        firstName: '',
        lastName: '',
        mobileNumber: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      email: 'email',
      userId: 'userId',
    }),
  },
  methods: {
    ...mapMutations({
      setUser: 'SET_USER',
    }),
    async create() {
      if (!validation(this.user, this.error, 2000)) return false;
      const loadingComponent = this.$buefy.loading.open();
      try {
        const res = (await CreateUser(this.user, this.userId)).data;
        this.setUser(res.user);
        this.$emit('created');
        loadingComponent.close();
        return true;
      } catch (error) {
        this.showError(error);
        loadingComponent.close();
        return false;
      }
    },
  },
  mounted() {
    this.user.email = this.email;
  },
};
</script>
