<template>
    <!-- eslint-disable -->
    <plain-layout>
        <div class="columns is-centered">
            <div v-if="!start" class="column is-6 has-text-centered">
                <h1 class="is-size-4 has-text-primary">Welcome To Your TeleMedicine App</h1>
                <h1 class="is-size-6">Initial setup is needed to begin using the app</h1>
                <b-button
                    type="is-primary"
                    expanded
                    @click="startSetup"
                    class="has-text-weight-bold mt-5"
                    :disabled="!canDoSetup"
                >
                    Start setup
                </b-button>
            </div>
            <div v-else class="column is-6">
                <template v-if="!adminCreated">
                    <h1 class="is-size-4 has-text-weight-medium has-text-primary">
                        Admin Profile
                    </h1>
                    <admin @created="adminCreated = true" />
                </template>
                <template v-else>
                    <h1 class="is-size-4 has-text-weight-medium has-text-primary">
                    Setup
                    </h1>
                    <h1 class="is-size-3">{{ tabs[step].label }}</h1>
                    <p class="has-text-grey">{{ tabs[step].description }}</p>
                    <component :is="tabs[step].component" v-bind="tabs[step].props" :editable="true" @saved="configSaved">
                        <template #actions="{ submit }">
                            <div class="columns is-centered">
                                <div class="column">
                                    <b-button
                                        v-if="step > 0"
                                        type="is-primary"
                                        expanded
                                        @click="step--"
                                        class="has-text-weight-bold"
                                    >
                                        Previous
                                    </b-button>
                                </div>
                                <div class="column">
                                    <b-button
                                        v-if="step >= (tabs.length - 1)"
                                        type="is-primary"
                                        expanded
                                        @click="submit() && finish()"
                                        class="has-text-weight-bold"
                                    >
                                        Finish
                                    </b-button>
                                    <b-button
                                        v-else
                                        type="is-primary"
                                        expanded
                                        @click="submit()"
                                        class="has-text-weight-bold"
                                    >
                                        Next
                                    </b-button>
                                </div>
                            </div>

                        </template>
                    </component>
                </template>
            </div>
            
        </div>
    </plain-layout>
</template>

<script>
/*eslint-disable */ 
import { get, set } from '@/api/setting';
import { ADMIN } from '@/config/user';

import PlainLayout from '@/Plain';
import Admin from './settings/Admin.vue';
import Organization from './settings/Organization.vue';
import Appointment from './settings/Appointment.vue';
import Paystack from './settings/Paystack.vue';
import AfricaTalking from './settings/AfricaTalking.vue';
import Mailgun from './settings/Mailgun.vue';
import Intercom from './settings/Intercom.vue';
import { mapGetters } from 'vuex';

export default {
    name: "Setup",
    metaInfo() {
    return {
      title: 'Setup',
    };
  },
    components: {
        PlainLayout, Admin,
        Organization, Appointment, Paystack, 
        AfricaTalking, Mailgun, Intercom,
    },
    data() {
        return {
            start: false,
            step: 0,
            config: {},
            adminCreated: true,
            adminProfile: ADMIN,
        };
    },

    computed: {
        ...mapGetters([
            'profil', 'settings', 'user',
        ]),
        canDoSetup() {
            if(!this.user) return true;
            return this.profil === this.adminProfile;
        },
        tabs(){
            return [
                {
                    label: 'Organization',
                    component: 'organization',
                    props: {
                        config: this.config.organization,
                        settingsKey: 'organization',
                    },
                    description: 'Basic information about your organization'
                },
                {
                    label: 'Appointment',
                    component: 'appointment',
                    props: {
                        config: this.config.appointment,
                        settingsKey: 'appointment',
                    },
                    description: 'Configure appointment.'
                },
                {
                    label: 'Paystack',
                    component: 'paystack',
                    props: {
                        config: this.config.paystack,
                        settingsKey: 'paystack',
                    },
                    description: 'Paystack will be used to process payment, configure your public and secret keys.'
                },
                {
                    label: 'AfricaTalking',
                    component: 'africa-talking',
                    props: {
                        config: this.config.africaTalking,
                        settingsKey: 'africaTalking',
                    },
                    description: 'AftricaTalking is a service to power your appointment conference calls and SMS.'
                },
                {
                    label: 'Mailgun',
                    component: 'mailgun',
                    props: {
                        config: this.config.mailgun,
                        settingsKey: 'mailgun',
                    },
                    description: 'Mailgun service power your emails.'
                },
                {
                    label: 'Intercom',
                    component: 'intercom',
                    props: {
                        config: this.config.intercom,
                        settingsKey: 'intercom',
                    },
                    description: 'Intercom powers your customer support'
                }
            ]
        }
    },

    mounted(){
        if(this.settings && this.settings.setup && this.settings.setup.completed) {
            this.$router.push({ name: 'Home' });
            return;
        }
        
        if(!this.canDoSetup) {
            this.$buefy.toast.open({
                duration: 5000,
                message: 'Only an admin is allowed',
                position: 'is-bottom',
                type: 'is-danger',
            });
            return;
        }

        this.step = this.$route.params.tab ? this.tabs.findIndex(t => t.component == this.$route.params.tab) : 0;
        if(this.step > 0) this.startSetup();
    },

    methods:{
        async startSetup() {
            this.start = true;

            const loadingComponent = this.$buefy.loading.open();

            const { data } = await get();

            this.config = data;

            loadingComponent.close();
        },

        finish() {
            const loadingComponent = this.$buefy.loading.open();
            set({
                completed: new Date()
            }, 'setup').then(() => {
                this.$router.push({ name: 'Home' });
            })
            .finally(() => {
                loadingComponent.close();
            })
        },
        configSaved(settings) {
            this.config[this.tabs[this.step].props.settingsKey] = settings;
            if(this.step < (this.tabs.length - 1)) this.step++;
        },
    },

    watch: {
        step: {
            immediate: false,
            handler(step){
                this.$router.push({name: this.$route.name, params: {tab: this.tabs[step].component}})
            }
        }
    }

};
</script>

<style>
    fieldset {
        border: 2px solid #f7f7f7;
        margin: 10px 0;
        padding: 10px;
    }
    fieldset legend {
        font-size: 1.5rem;
        font-weight: bold;
    }
</style>
